<template>
  <!-- zx 2021-6-3 -->
  <div>
    <!-- <div class="live1" v-lazy:background-image="require('./live_streaming_img/banner.png')"> -->
    <div class="live1">
       <div class="start">
       <img v-lazy='require("./live_streaming_img/lzHWDWK4.webp")' class="start_img">
     </div>
     <div class="bannert">
        <div class="modal1">在线直播</div>
      <div class="modal2">随时随地，看世界</div>
     </div>
     
    </div>
    <div class="live2">
      <div class="modal3">灵活的录制方式</div>
      <div class="modal4">支持多种使用场景的录制方式，包含按域名、流名称、指定时间段、精彩视频、纯音频录制方式</div>
     <div class="img-1">
        <img v-lazy="require('./live_streaming_img/2lEtPEiz.webp')" class="modal5">
     </div>
    </div>
    <!-- <div class="live3" v-lazy:background-image="require('./live_streaming_img/img_39.png')"> -->
    <div class="live3">
     <div class="img-3">
       <img v-lazy='require("./live_streaming_img/OqOrqg0P.webp")' class="img-4">
     </div>
     <div class="literal1">
        <div class="modal6">二次制作和分发能力</div>
      <div class="modal7">基于腾讯云点播的音视频处理能力，提供快速对视频内容进行二次制作、剪辑，分发等能力</div>
     </div>
    </div>
    <div class="live4">
      <div class="moda18">直播优势</div>
      <div class="moda19">
         <div class="img-4">
            <img v-lazy="require('./live_streaming_img/o1jFYgKu.webp')" class="moda21">
         </div>
          <div class="modal23">
           <div>
              <div class="moda22">
              （1）提供多样的推流服务，提高直播可用性
            </div>
            <div class="moda22 mt32">
              （2）区别传统，全新互动
            </div>
            <div class="moda22 mt32">
              （3）聚集人气，营造氛围
            </div>
           </div>
          </div>
      </div>
    </div>

 <div>
  <Header></Header>
  <Footer></Footer>
  </div>
</div>
</template>
<script>
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
//引入jquery
export default {
components: {
    Header,
    Footer,
  },
}
</script>
<style lang="scss" scoped>
/* 动画效果 */
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.live1{
   height: 600px;
  width: 100%;
  // background-repeat:  no-repeat;
  // background-size: 100% 100%;
}
.start_img{
   height: 600px;
  width: 100%;
}
.start{
   height: 600px;
  width: 100%;
  display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 200px;
		height: 200px;   

	}
	// 实际图片大小
	img[lazy="loaded"] {
		 height: 600px;
  width: 100%;
	}
}
.bannert{
   position: absolute;
    left: 361px;
    top: 230px;

}
.modal1{
  color: rgba(255, 255, 255, 1);
  font-size: 48px;
  // padding-top: 230px;
  // padding-left: 360px;
  text-align: left;
}
.modal1{
   animation: sport 0.5s;
  transform: translateY(0px);
}
.modal2{
   color: rgba(255, 255, 255, 1);
  font-size: 24px;
  // padding-left: 360px;
  margin-top: 24px;
  text-align: left;
}
.live2{
 margin: auto;
  margin-top: 80px;
  margin-bottom: 100px;
}
.modal3{
  color: rgba(51, 51, 51, 1);
  font-size: 40px;
}
.modal4{
  color: rgba(86, 86, 86, 1);
  font-size: 20px;
  margin-top: 24px;
}
.modal5{
  width: 1200px;
  height: 420px;
}
.img-1{
   width: 1200px;
  height: 420px;
  margin: auto;
   margin-top: 60px;
   display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 100px;
		height: 100px;
    

	}
	// 实际图片大小
	img[lazy="loaded"] {
		 width: 1200px;
  height: 420px;
	}
}
.live3{
   height: 720px;
  width: 100%;
  // background-repeat:  no-repeat;
  // background-size: 100% 100%
  position: relative;
}
.img-4{
 height: 720px;
  width: 100%;
}
.img-3{
   height: 720px;
  width: 100%;
   display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 200px;
		height: 200px;
    

	}
	// 实际图片大小
	img[lazy="loaded"] {
		 height: 720px;
  width: 100%;
	}
}
.literal1{
  position: absolute;
  top: 310px;
  left: 540px;
}
.modal6{
 color: rgba(255, 255, 255, 1);
  font-size: 40px;
  // padding-top: 310px;
}
.modal7{
  color: rgba(255, 255, 255, 0.88);
  font-size: 20px;
  margin-top: 24px;
}
.live4{
  margin-top: 80px;
  margin-bottom: 100px;
}
.moda18{
  color: rgba(51, 51, 51, 1);
  font-size: 40px;
}
.moda19{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}
.moda21{
  width: 600px;
  height: 324px;
}
.img-4{
  width: 600px;
  height: 324px;
  display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 50px;
		height: 50px;
    

	}
	// 实际图片大小
	img[lazy="loaded"] {
		 width: 600px;
  height: 324px;
	}
}
.modal23{
   height: 324px;
   width: 600px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 48px;
}
.modal2{
   animation: sport 0.5s;
  transform: translateY(0px);
}
.moda22{
  color: rgba(86, 86, 86, 1);
  font-size: 20px;
}
.mt32{
  margin-top: 32px;
}
</style>
  
